import { action, deprecated } from 'typesafe-actions';
import {
    BillingInfoModel,
    CreditCardModel,
    AddCreditCardModel,
    UpdateBillingInfoModel,
    StripeSetupIntentModel,
    ConfirmPaymentRequest,
    InvoiceModel,
    AdministratorModel,
    InviteAdministratorRequestModel,
    SubmitCouponCodeModel,
    CouponCodesModel
} from './AccountModels';

const { createStandardAction } = deprecated;

export const getBillingInfo = createStandardAction("account/GET_BILLING_INFO")<number>();
export const getBillingInfoCompleted = createStandardAction("account/GET_BILLING_INFO_COMPLETED")<BillingInfoModel>();

export const updateBillingInfo = createStandardAction("account/UPDATE_BILLING_INFO")<UpdateBillingInfoModel>();
export const updateBillingInfoCompleted = createStandardAction("account/BILLING_INFO_UPDATED")<UpdateBillingInfoModel>();

export const addCreditCard = createStandardAction("account/ADD_CREDIT_CARD")<AddCreditCardModel>();

export const addCreditCardCompleted = createStandardAction("account/ADD_CREDIT_CARD_COMPLETED")<AddCreditCardModel>();

export const updateCreditCard = createStandardAction("account/UPDATE_CREDIT_CARD")<CreditCardModel>();
export const updateCreditCardCompleted = createStandardAction("account/CREDIT_CARD_UPDATED")<CreditCardModel>();

export const getCreditCardInfo = createStandardAction("account/GET_CREDIT_CARD_INFO")();
export const getCreditCardInfoCompleted = createStandardAction("account/GET_CREDIT_CARD_INFO_COMPLETED")<CreditCardModel[]>();

export const validateCreditCardModel = createStandardAction("account/VALIDATE_CREDIT_CARD")<AddCreditCardModel>();
export const validateCreditCardModelCompleted = createStandardAction("account/CREDIT_CARD_VALIDATED")<boolean>();

export const validateBillingInfoModel = createStandardAction("account/VALIDATE_BILLING_INFO")<UpdateBillingInfoModel>();
export const validateBillingInfoModelCompleted = createStandardAction("account/BILLING_INFO_VALIDATED")<boolean>();

export const createSetupIntent = createStandardAction("account/CREATE_SETUP_INTENT")();
export const createSetupIntentCompleted = createStandardAction("account/SETUP_INTENT_CREATED")<StripeSetupIntentModel>();

export const setRequireStripeAction = createStandardAction("account/SET_STRIPE_ACTION_REQUIRED")<{ actionRequired: boolean, paymentIntentClientSecret: string }>();

export const confirmStripePayment = createStandardAction("account/CONFIRM_STRIPE_PAYMENT")<ConfirmPaymentRequest>();

export const updateSubscription = createStandardAction("account/UPDATE_SUBSCRIPTION")<number>();
export const setUpdateSubscriptionFlag = createStandardAction("account/SET_UPDATE_SUBSCRIPTION_FLAG")<boolean>();

export const getInvoices = createStandardAction("account/GET_INVOICES")();
export const getInvoicesCompleted = createStandardAction("account/GET_INVOICES_COMPLETED")<InvoiceModel[]>();

export const getUsers = createStandardAction("account/GET_USERS")();
export const getUsersCompleted = createStandardAction("account/GET_USERS_COMPLETED")<AdministratorModel[]>();

export const deleteUser = createStandardAction("account/DELETE_USER")<number>();
export const deleteUserCompleted = createStandardAction("account/USER_DELETED")<number>();

export const getInvoice = createStandardAction("account/GET_INVOICE")<number>();

export const setSuccessfulPaymentFlag = createStandardAction("account/SET_SUCCESSFUL_PAYMENT_FLAG")<Boolean>();

export const deactivateSubscription = createStandardAction("account/DEACTIVATE_SUBSCRIPTION")();
export const setDeactivateSubscriptionFlag = createStandardAction("account/SET_DEACTIVATE_SUBSCRIPTION_FLAG")<boolean>();

export const setDefaultCreditCard = createStandardAction("account/SET_DEFAULT_CREDIT_CARD")<number>();

export const inviteUser = createStandardAction("account/INVITE_USER")<InviteAdministratorRequestModel>();
export const setInviteUserFlag = createStandardAction("account/SET_INVITE_USER_FLAG")<boolean>();

export const submitCouponCode = createStandardAction("account/SUBMIT_COUPON_CODE")<SubmitCouponCodeModel>();
export const submitCouponCodeCompleted = createStandardAction("account/SUBMIT_COUPON_CODE_COMPLETED")<boolean>();

export const loadCouponCodes = createStandardAction('account/COUPON_CODES_LOAD')();
export const loadCouponCodesCompleted = createStandardAction('account/COUPON_CODES_LOADED')<CouponCodesModel[]>();

export const setPrimaryOrgId = createStandardAction('account/SET_PRIMARY_ORG_ID')<number>();