import {takeLatest, put, call, take, select} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import * as actions from './signInActions';
import * as authActions from '../../auth/redux/actions';
import * as loadingActions from '../../shared/loading/loadingStateActions';
import * as authApi from '../../api/authenticationApi';
import {Credentials} from "./signInData";
import {OrganizationModel} from "../../shared/SharedModels";
import * as sharedActions from "../../shared/sharedActions";
import * as routerActions from "../../shared/router/routerActions";
import * as errorActions from "../../shared/errors/errorActions";
import * as accountActions from "../../AccountPage/accountActions"
import {logout} from "../../auth/authAPI";
import {appSettings} from '../../config/appSettings';
import {ValidationError} from "../../shared/errors/ErrorModels";
import {CustomError} from "../../shared/errors/ErrorModels";
import {customErrorMapper, validationErrorMapper} from "../../shared/errors/ErrorMappers";
import {CredentialsModel, SignInResponse} from "./signInModels";
import {organizationSelector} from "../../shared/sharedSelectors";
import {Tina4SignInTokenResult} from "../SignUpPage/signUpData";

const apiName = appSettings.apisMetadata.find(x => x.id === 'rms')!.name;

export function* signInSaga() {
    yield takeLatest(getType(actions.signIn), signIn);
    yield takeLatest(getType(actions.switchOrganization), switchOrganization);
    yield takeLatest(getType(sharedActions.signOut), signOut);
    yield takeLatest(getType(actions.validateCredentials), doValidateCredentials);
}

function* signIn({payload}: ReturnType<typeof actions.signIn>) {
    yield put(loadingActions.begin());
    const {credentials, options} = payload;
    const url = 'sign-in-token'

    yield put(actions.validateCredentials({...credentials}));

    const isValid: ActionType<typeof actions.validateCredentialsCompleted> = yield take(getType(actions.validateCredentialsCompleted));
    if (!isValid.payload) {
        yield put(loadingActions.reset());
        return;
    }

    try {
        yield call(logout, apiName);

        const data: Credentials = {
            ...credentials,
        };

        const authResponseData: Response = yield call(() => authApi.initialAuthToken(url));

        const authResponseBody: Tina4SignInTokenResult = yield call(() => authResponseData);
        // need to define type
        const responseData: Response = yield call(() => authApi.verify(data, authResponseBody.access_token));

        const responseBody: SignInResponse | SignInResponse[] = yield call(() => responseData.json());

        if (responseData.ok) {
            const accessToken = Array.isArray(responseBody) ? responseBody[0]?.access_token : responseBody?.access_token;
            const grantType = Array.isArray(responseBody) ? responseBody[0]?.grant_type : responseBody?.grant_type;
            const expiresIn = Array.isArray(responseBody) ? responseBody[0]?.expires_in : responseBody?.expires_in;
            if (!accessToken) {
                // bad request error
                const errorMessage = Array.isArray(responseBody) ? responseBody[0]?.error_message : responseBody?.error_message;
                if (errorMessage) {
                    const model: CustomError = customErrorMapper(errorMessage);
                    yield put(errorActions.clearValidationErrors());
                    yield put(errorActions.addError(model));
                }

                return;
            }

            // setTokens to local/sessionStorage manually
            yield put(authActions.login({apiName: "api", tokens: {access_token: accessToken, grant_type: grantType, expires_in: expiresIn}}));
            // hardcoded
            const primaryToken = Array.isArray(responseBody) ? responseBody[1]?.primary_token : responseBody?.primary_token;
            if (primaryToken) {
                const primaryGrantType = Array.isArray(responseBody) ? responseBody[1]?.grant_type : responseBody?.grant_type;
                const primaryExpiresIn = Array.isArray(responseBody) ? responseBody[1]?.expires_in : responseBody?.expires_in;
                yield put(authActions.setPrimaryToken({name: "primaryToken", tokens: {primary_token: primaryToken, grant_type: primaryGrantType, expires_in: primaryExpiresIn}}));
            }

            yield put(sharedActions.getProfile());

            yield take(getType(sharedActions.loadUserProfileCompleted));


        } else {

            const errors = Array.isArray(responseBody) ? responseBody[0]?.errors : responseBody?.errors;
            yield call(handleErrorRequest, errors);

        }


    } catch (e) {

        yield put(loadingActions.reset());

    } finally {
        yield put(loadingActions.complete());

        const organization: OrganizationModel = yield select(organizationSelector);

        localStorage.setItem('primaryOrgId', JSON.stringify(organization.id))
        yield put(accountActions.setPrimaryOrgId(organization.id))
        if (options && !organization) yield put(routerActions.redirect(options.returnUrl ?? '/signin'));
        else yield put(routerActions.redirect(`/org${organization.id}/setup/customize`));
    }
}

function* switchOrganization(action: ActionType<typeof actions.switchOrganization>) {
    yield put(loadingActions.begin());

    try {
        const agentOrganizationId = action.payload;
        yield put(authActions.removeAgentToken());

        const responseData: Response = yield call(() => authApi.switchAgentToken(agentOrganizationId));

        const responseBody: SignInResponse | SignInResponse[] = yield call(() => responseData.json());

        if (responseData.ok) {
            const accessToken = Array.isArray(responseBody) ? responseBody[0]?.access_token : responseBody?.access_token;
            const grantType = Array.isArray(responseBody) ? responseBody[0]?.grant_type : responseBody?.grant_type;
            const expiresIn = Array.isArray(responseBody) ? responseBody[0]?.expires_in : responseBody?.expires_in;
            if (!accessToken) {
                // bad request error
                const errors = Array.isArray(responseBody) ? responseBody[0]?.errors : responseBody?.errors;
                yield call(handleErrorRequest, errors);
                yield put(errorActions.clearValidationErrors());

                return;
            }

            yield put(authActions.setAgentToken({apiName: "api", tokens: {access_token: accessToken, grant_type: grantType, expires_in: expiresIn}}));

            yield put(sharedActions.switchOrganizationProfile(agentOrganizationId));

            yield take(getType(sharedActions.loadUserProfileCompleted));

        } else {
            const errors = Array.isArray(responseBody) ? responseBody[0]?.errors : responseBody?.errors;
            yield call(handleErrorRequest, errors);

        }

    } catch (e) {
        yield put(loadingActions.reset());

    } finally {
        yield put(loadingActions.complete());
        // window.location.reload();
    }
}

function* signOut(action: ActionType<typeof sharedActions.signOut>) {
    const {returnUrl} = action.payload;

    try {
        yield call(logout, apiName);

        let redirectUrl = "/signin";
        if (returnUrl && !/signin/.test(returnUrl)) {
            redirectUrl = redirectUrl + `?returnUrl=${returnUrl}`;
            yield put(routerActions.redirect(redirectUrl));
        }

        yield put(sharedActions.getWhiteLabelProfile());
        yield put(sharedActions.whiteLabelCheckCompleted());
    } catch (e) {

    } finally {

    }
}

// todo: move this to the shared saga
function* handleErrorRequest(errs: any) {
    let localErrors: CustomError[] | [] = [];
    let validationErrors: ValidationError[] | [] = [];

    const errObjKeys: { [key: string]: any } = Object.keys(errs);

    errObjKeys.forEach((key: any) => {
        // validation errors
        if (errs[key] && errs[key].length) {
            errs[key].map((err: any) => {
                const model = validationErrorMapper(key, err);
                validationErrors = [...validationErrors, model];
            });

        } else {
            const model = customErrorMapper(errs[key]);
            localErrors = [...localErrors, model];
        }
    });

    yield put(errorActions.clearValidationErrors());
    if (localErrors.length) yield put(errorActions.setErrors(localErrors));

    if (validationErrors.length)
        yield put(errorActions.setValidationErrors(validationErrors));
}

function* doValidateCredentials(action: ActionType<typeof actions.validateCredentials>) {
    const model = action.payload;
    const isValid: boolean = yield doValidateCredentialsBase(model);
    yield put(actions.validateCredentialsCompleted(isValid));
}

function* doValidateCredentialsBase(model: CredentialsModel) {
    yield put(errorActions.clearValidationErrors());

    const validationErrors: ValidationError[] = [];
    const emailRegExp = /^\S+@\S+\.\S+$/;

    if (!model.email) {
        validationErrors.push({name: 'Email', message: 'Email cannot be empty'});
    } else if (!model.email.match(emailRegExp)) {
        validationErrors.push({name: 'Email', message: 'The wrong format is used for email'});
    }

    if (!model.password) {
        validationErrors.push({name: 'Password', message: 'Password cannot be empty'});
    } else if (model.password.length < 4) {
        validationErrors.push({name: 'Password', message: 'Password cannot be shorter than 4 symbols'});
    }

    if (validationErrors.length > 0) {
        yield put(errorActions.setValidationErrors(validationErrors));

        return false;
    }

    return true;
}

export {handleErrorRequest};
