import { getType } from 'typesafe-actions';
import { AccountModel, CreditCardModel } from './AccountModels';
import * as actions from './accountActions';

const initialAccountState: AccountModel = {
    billingInfo: undefined,
    creditCards: [],
    stripe: {
        setupIntent: null,
        stripeActionRequired: false,
        paymentIntentClientSecret: "",
    },
    isSubscriptionUpdated: false,
    isSubscriptionDeactivated: false,
    isSuccessfulPayment: false,
    invoice: {
        all: [],
    },
    users: {
        all: [],
        isUserInvited: false,
    },
    couponCodes: {
        all: []
    },
    primaryOrgId: localStorage.getItem('primaryOrgId') ? JSON.parse(localStorage.getItem('primaryOrgId') as string) : 0,
}

const accountReducer = (state: AccountModel = initialAccountState, action: any) => {

    switch(action.type) {
        // implement via getType
        case getType(actions.getBillingInfoCompleted):
            return {
                ...state,
                billingInfo: {
                    ...action.payload,
                },
            }
        case getType(actions.getCreditCardInfoCompleted):
            return {
                ...state,
                creditCards: action.payload,
            }
        case getType(actions.addCreditCardCompleted):
            return {
                ...state,
                creditCards: [...state.creditCards, action.payload],
            }
        case getType(actions.updateCreditCardCompleted):
            return {
                ...state,
                // or how
                creditCards: state.creditCards.map((card: CreditCardModel) =>
                    card.id === action.payload.id ? { ...card, ...action.payload } : card),
            }
        case getType(actions.createSetupIntentCompleted):
            return {
                ...state,
                // or how
                stripe: {
                    ...state.stripe,
                    setupIntent: action.payload,
                    
                }
            }
        case getType(actions.setRequireStripeAction):
            return {
                ...state,
                // or how
                stripe: {
                    ...state.stripe,
                    stripeActionRequired: action.payload.actionRequired,
                    paymentIntentClientSecret: action.payload.paymentIntentClientSecret,
                }
            }
        case getType(actions.setUpdateSubscriptionFlag):
            return {
                ...state,
                isSubscriptionUpdated: action.payload,
            }
        case getType(actions.getInvoicesCompleted):
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    all: action.payload,
                },
            }
        case getType(actions.getUsersCompleted):
            return {
                ...state,
                users: {
                    ...state.users,
                    all: action.payload,
                },
            }
        case getType(actions.deleteUserCompleted):
            return {
                ...state,
                users: {
                    ...state.users,
                    all: state.users.all.filter(u => u.id !== action.payload),
                },
            }    
        case getType(actions.setSuccessfulPaymentFlag):
            return {
                ...state,
                isSuccessfulPayment: action.payload,
            }
        case getType(actions.setDeactivateSubscriptionFlag):
            return {
                ...state,
                isSubscriptionDeactivated: action.payload,
            }
        case getType(actions.setInviteUserFlag):
            return {
                ...state,
                users: {
                    ...state.users,
                    isUserInvited: action.payload,
                },
            }
        case getType(actions.loadCouponCodesCompleted):
            return {
                ...state,
                couponCodes: {
                    ...state.couponCodes,
                    all: action.payload,
                },
            }
        case getType(actions.setPrimaryOrgId):
            return {
                ...state,
                primaryOrgId: action.payload
            }
        default:
            return {
                ...state,
            };
    }
}

export default accountReducer;